<template>
  <div>
    <app-list request-url="/api/roles/table" entity="roles">
      <template #header="{item: role}">
        <div class="text-center">
          <router-link :to="`/roles/${role.id}`">
            <div class="font-size-h3 label label-xl label-light-info label-pill label-inline text-hover-primary">{{  role.name }}</div>
          </router-link>
        </div>
      </template>
      <template #body="{item: role}">
        <children-symbols
            entity="users"
            :children="role.users"
            with-acronym
            field="full_name"
        />
      </template>
    </app-list>

    <portal to="subheader-toolbar">
      <b-button variant="transparent-white" @click="$router.push({name: 'createRole'})">
        {{ $t('btn.create_role') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppList from '@/components/lists/AppList';
import AppModal from '@/components/modals/AppModal';
import ChildrenSymbols from '@/components/lists/components/ChildrenSymbols';

export default {
  components: {
    AppCard,
    AppList,
    AppModal,
    ChildrenSymbols
  },
  data: () => ({
    deleteRoleID: null,
    updateData: false,
    userIconsNumber: 5,
    fields: [
      {key: 'name', sortable: true, variant: 'primary'},
      {key: 'last_name', sortable: true},
      {key: 'email'},
    ]
  }),
  watcher: {
    updateData(val) {
      if (val) {
        setTimeout(() => {
          this.updateData = false;
        }, 200)
      }
    }
  },
  methods: {
    getAcronym(fullName) {
      let result = '';
      let tokens = fullName.split(/\s/);
      for (let i = 0; i < tokens.length; i++) {
        result += tokens[i].substring(0, 1).toUpperCase();
      }
      return result;
    },
    getChunkedUsers(users) {
      return users.slice(0, this.userIconsNumber);
    },
    getUsersCount(users) {
      return users?.length || 0;
    },
    openDeleteModal(roleID) {
      this.deleteRoleID = roleID;
      this.$bvModal.show('delete-role-modal');
    },
    deleteItem() {
      this.$store.dispatch('rolesStore/DELETE', this.deleteRoleID).then(() => {
        this.updateData = true;
      });
    }
  }
}
</script>